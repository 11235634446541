exports.onRouteUpdate = ({ location }, pluginOptions) => {
  if (process.env.NODE_ENV === `production`) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'virtualPageView',
        virtualPagePath: location.pathname,
      })
    }, 50)
  }
}

exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    require('intersection-observer')
  }
}
