// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ekwipunek-samodzielniaka-js": () => import("./../../../src/pages/ekwipunek-samodzielniaka.js" /* webpackChunkName: "component---src-pages-ekwipunek-samodzielniaka-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-index-js": () => import("./../../../src/pages/quiz/index.js" /* webpackChunkName: "component---src-pages-quiz-index-js" */),
  "component---src-pages-slownik-js": () => import("./../../../src/pages/slownik.js" /* webpackChunkName: "component---src-pages-slownik-js" */),
  "component---src-pages-strefa-dziecka-js": () => import("./../../../src/pages/strefa-dziecka.js" /* webpackChunkName: "component---src-pages-strefa-dziecka-js" */),
  "component---src-pages-strefa-nauczyciela-index-js": () => import("./../../../src/pages/strefa-nauczyciela/index.js" /* webpackChunkName: "component---src-pages-strefa-nauczyciela-index-js" */),
  "component---src-pages-strefa-nauczyciela-konkurs-index-js": () => import("./../../../src/pages/strefa-nauczyciela/konkurs/index.js" /* webpackChunkName: "component---src-pages-strefa-nauczyciela-konkurs-index-js" */),
  "component---src-pages-strefa-nauczyciela-konkurs-materialy-js": () => import("./../../../src/pages/strefa-nauczyciela/konkurs/materialy.js" /* webpackChunkName: "component---src-pages-strefa-nauczyciela-konkurs-materialy-js" */),
  "component---src-pages-strefa-nauczyciela-konkurs-potwierdzenie-js": () => import("./../../../src/pages/strefa-nauczyciela/konkurs/potwierdzenie.js" /* webpackChunkName: "component---src-pages-strefa-nauczyciela-konkurs-potwierdzenie-js" */),
  "component---src-pages-strefa-nauczyciela-konkurs-zgloszenie-klasy-js": () => import("./../../../src/pages/strefa-nauczyciela/konkurs/zgloszenie-klasy.js" /* webpackChunkName: "component---src-pages-strefa-nauczyciela-konkurs-zgloszenie-klasy-js" */),
  "component---src-pages-strefa-nauczyciela-konkurs-zgloszenie-pracy-js": () => import("./../../../src/pages/strefa-nauczyciela/konkurs/zgloszenie-pracy.js" /* webpackChunkName: "component---src-pages-strefa-nauczyciela-konkurs-zgloszenie-pracy-js" */),
  "component---src-pages-strefa-rodzica-7-stereotypow-na-temat-pieniedzy-js": () => import("./../../../src/pages/strefa-rodzica/7-stereotypow-na-temat-pieniedzy.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-7-stereotypow-na-temat-pieniedzy-js" */),
  "component---src-pages-strefa-rodzica-baza-wiedzy-js": () => import("./../../../src/pages/strefa-rodzica/baza-wiedzy.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-baza-wiedzy-js" */),
  "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-aleksander-naganowski-js": () => import("./../../../src/pages/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/aleksander-naganowski.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-aleksander-naganowski-js" */),
  "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-js": () => import("./../../../src/pages/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-js" */),
  "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-maciej-samcik-js": () => import("./../../../src/pages/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/maciej-samcik.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-maciej-samcik-js" */),
  "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-monika-sajkowska-js": () => import("./../../../src/pages/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/monika-sajkowska.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-monika-sajkowska-js" */),
  "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-natalia-tur-js": () => import("./../../../src/pages/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/natalia-tur.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-natalia-tur-js" */),
  "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-tatiana-mindewicz-puacz-js": () => import("./../../../src/pages/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/tatiana-mindewicz-puacz.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-czy-dawanie-kieszonkowego-sie-oplaca-tatiana-mindewicz-puacz-js" */),
  "component---src-pages-strefa-rodzica-index-js": () => import("./../../../src/pages/strefa-rodzica/index.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-index-js" */),
  "component---src-pages-strefa-rodzica-jak-budowac-postawe-odpowiedzialnosci-finansowej-js": () => import("./../../../src/pages/strefa-rodzica/jak-budowac-postawe-odpowiedzialnosci-finansowej.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-jak-budowac-postawe-odpowiedzialnosci-finansowej-js" */),
  "component---src-pages-strefa-rodzica-jak-ustalic-wysokosc-kieszonkowego-js": () => import("./../../../src/pages/strefa-rodzica/jak-ustalic-wysokosc-kieszonkowego.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-jak-ustalic-wysokosc-kieszonkowego-js" */),
  "component---src-pages-strefa-rodzica-jak-wdrazac-dzieci-w-prowadzenie-doroslego-domowego-budzetu-js": () => import("./../../../src/pages/strefa-rodzica/jak-wdrazac-dzieci-w-prowadzenie-doroslego-domowego-budzetu.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-jak-wdrazac-dzieci-w-prowadzenie-doroslego-domowego-budzetu-js" */),
  "component---src-pages-strefa-rodzica-kieszonkowe-js": () => import("./../../../src/pages/strefa-rodzica/kieszonkowe.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-kieszonkowe-js" */),
  "component---src-pages-strefa-rodzica-mamo-kup-mi-jak-reagowac-na-dzieciece-prosby-js": () => import("./../../../src/pages/strefa-rodzica/mamo-kup-mi-jak-reagowac-na-dzieciece-prosby.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-mamo-kup-mi-jak-reagowac-na-dzieciece-prosby-js" */),
  "component---src-pages-strefa-rodzica-quiz-index-js": () => import("./../../../src/pages/strefa-rodzica/quiz/index.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-quiz-index-js" */),
  "component---src-pages-strefa-rodzica-roznice-finansowe-w-klasie-js": () => import("./../../../src/pages/strefa-rodzica/roznice-finansowe-w-klasie.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-roznice-finansowe-w-klasie-js" */),
  "component---src-pages-strefa-rodzica-twoje-dziecko-mysli-ze-kieszonkowe-spada-z-nieba-js": () => import("./../../../src/pages/strefa-rodzica/twoje-dziecko-mysli-ze-kieszonkowe-spada-z-nieba.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-twoje-dziecko-mysli-ze-kieszonkowe-spada-z-nieba-js" */),
  "component---src-pages-strefa-rodzica-tysiak-js": () => import("./../../../src/pages/strefa-rodzica/tysiak.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-tysiak-js" */)
}

